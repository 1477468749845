import React, { useEffect, useState } from "react";
import { graphql, useStaticQuery } from "gatsby";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { Helmet } from "react-helmet";
import axios from "axios";
import { Spinner } from "react-bootstrap";
import ReviewCard from "../components/review-card";
import Layout from "../components/layout";
import Navigation from "../components/navigation";
import { GatsbyImage } from "gatsby-plugin-image";
import ReactPlayer from "react-player";
import { checkPropertiesForNull } from "../../utils";
import { SafeHtmlParser } from "../components/safeHtmlParser";

const Reviews = () => {
  const data = useStaticQuery(
    graphql`
      query {
        allWpReview {
          nodes {
            reviewContent {
              author
              date
              review
            }
          }
        }
        site {
          siteMetadata {
            siteUrl
          }
        }
        pageData: wpPage(slug: { eq: "success-stories" }) {
          successStoriesFields {
            successStoriesBanner {
              successStoriesBannerHeading
            }
            successStoriesCards {
              successStoriesCardItem {
                successStoriesHeading
                successStoriesMediaColumn
                successStoriesContentColumn
                successStoriesText
                successStoriesMedia
                successStoriesVideo {
                  node {
                    altText
                    mediaItemUrl
                  }
                }
                successStoriesImage {
                  node {
                    altText
                    localFile {
                      childImageSharp {
                        gatsbyImageData(
                          formats: [AUTO, WEBP]
                          quality: 100
                          transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                          layout: CONSTRAINED
                          placeholder: BLURRED
                        )
                      }
                    }
                  }
                }
              }
            }
          }
          seoFields {
            metaTitle
            metaDescription
            opengraphTitle
            opengraphDescription
            productSchema
            image {
              node {
                altText
                publicUrl
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      formats: [AUTO, WEBP]
                      quality: 100
                      transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                      layout: CONSTRAINED
                      placeholder: BLURRED
                    )
                    original {
                      width
                      height
                    }
                  }
                }
              }
            }
          }
        }
      }
    `
  );

  const [reviewsTotal, setReviewsTotal] = useState([]);
  var reviews = [];
  var totalReviews = data.allWpReview.nodes.map((review) => ({
    reviewBody: review.reviewContent.review,
    datePublished: new Date(review.reviewContent.date),
    author: review.reviewContent.author,
    source: null,
  }));

  useEffect(() => {
    axios
      .get(
        `https://www.local-marketing-reports.com/external/showcase-reviews/widgets/a6fb6da2f05bccca1cad5680d16883f5aee57b35`
      )
      .then((res) => {
        const reviewData = res.data.results;
        reviews = reviewData;
        handleReviews();
        if(document.getElementById("loading-spinner")) {
          document.getElementById("loading-spinner").style.display = "none";
        }
        if( document.getElementById("no-reviews")) {
          document.getElementById("no-reviews").style.display = "block";
        }
      });
  });

  const handleReviews = () => {
    totalReviews = totalReviews.concat(
      reviews.map((review) => ({
        reviewBody: review.reviewBody,
        datePublished: new Date(review.datePublished),
        author: review.author,
        source: review.source,
      }))
    );
    setReviewsTotal(
      totalReviews.sort((a, b) => b.datePublished - a.datePublished)
    );
  };

  const {
    pageData: { seoFields, successStoriesFields },
  } = data;

  const { successStoriesBanner, successStoriesCards } = successStoriesFields;

  const siteUrl = data.site.siteMetadata.siteUrl;

  const breadcrumb = {
    "@context": "http://schema.org",
    "@type": "BreadcrumbList",
    itemListElement: [
      {
        "@type": "ListItem",
        position: 1,
        name: "Home",
        item: `${siteUrl}`,
        id: `${siteUrl}`,
      },
      {
        "@type": "ListItem",
        position: 2,
        name: "Success Stories",
        item: `${siteUrl}/success-stories`,
        id: `${siteUrl}/success-stories`,
      },
    ],
  };

  return (
    <Layout>
      <Helmet>
        {" "}
        <script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
      </Helmet>
      <GatsbySeo
        title={seoFields?.metaTitle}
        description={seoFields?.metaDescription}
        language="en"
        openGraph={{
          type: "website",
          url: `${siteUrl}`,
          title: `${seoFields?.opengraphTitle}`,
          description: `${seoFields?.opengraphDescription}`,
          images: [
            {
              url: `${seoFields?.image?.node.localFile.publicURL}`,
              width: `${seoFields?.image?.node.localFile.childImageSharp.original.width}`,
              height: `${seoFields?.image?.node.localFile.childImageSharp.original.height}`,
              alt: `${seoFields?.image?.node.altText}`,
            },
          ],
        }}
      />

    
        <section
          style={{
            background:
              "transparent linear-gradient(180deg, #E0F1F9 0%, #FFFFFF 100%) 0% 0% no-repeat padding-box",
            opacity: 1,
          }}
        >
          <Navigation background="none" />
          <Container>
            {" "}
            <Row>
              <Col>
                <h1 className="text-primary mt-8 mb-5 text-center display-4 jost-bold">
                  {successStoriesBanner?.successStoriesBannerHeading || "What People Say"}
                </h1>
              </Col>
            </Row>
          </Container>
        </section>

      {successStoriesCards &&
        !checkPropertiesForNull(successStoriesCards, [
          "successStoriesCardItem",
        ]) && (
          <Container className="pb-5 pt-md-5">
            {successStoriesCards?.successStoriesCardItem.map((item, i) => (
              <Row className="pb-5 pb-md-5 gy-3 gy-md-5  align-items-center">
                <Col
                  xl={{
                    span: item.successStoriesMediaColumn,
                    order: i % 2 !== 0 ? "first" : "last",
                  }}
                  lg={{ span: 4, order: i % 2 !== 0 ? "first" : "last" }}
                >
                  {item.successStoriesMedia === "Video" ? (
                    <ReactPlayer
                      controls
                      className="w-100 h-auto"
                      url={item?.successStoriesVideo?.node.mediaItemUrl}
                    />
                  ) : (
                    <GatsbyImage
                      className="mw-100"
                      image={
                        item?.successStoriesImage?.node?.localFile
                          .childImageSharp.gatsbyImageData
                      }
                      alt={item?.successStoriesImage?.altText}
                    />
                  )}
                </Col>
                <Col lg={8} xl={item.successStoriesContentColumn}>
                  <h2 className="jost-bold pb-3 text-primary">
                    {item.successStoriesHeading}
                  </h2>
                  <SafeHtmlParser htmlContent={item.successStoriesText} />
                </Col>
              </Row>
            ))}
            {/* <Row className="py-5 g-5 gx-lg-7 align-items-center">
            <Col lg={{ span: 4, order: "last" }}>
              <GatsbyImage
                className="mw-100"
                image={data.tonyImg.localFile.childImageSharp.gatsbyImageData}
                alt={data.tonyImg.altText}
              />
            </Col>
            <Col>
              <h2 className="jost-bold text-primary">Tony</h2>
              <p>
                Prior to coming to Tracy’s classes, I had done Pilates for around
                5 years. When I moved to Eastbourne, I tried other classes, but I
                didn’t enjoy them, and decided to give it a rest. In 2021 I had
                extensive cancer surgery, which left my midriff feeling weak, and
                I felt that Pilates would help me regain core strength, in
                addition to lots of dog walking, which it has, thankfully.
              </p>
              <p>
                Luckily, my classes started with Tracy, who made me feel very
                welcome, as did the classmates, they are all such a lovely bunch.
                To anyone thinking of coming to classes, I'd say yes, yes, yes!
                Apart from enjoying the challenge, it's a very nice social
                experience, good for the mind and body, and ability is not an
                obstacle. I know I am probably the least talented member of my
                class, but I don't care, I just do what I can and nobody laughs at
                me!!
              </p>
            </Col>
          </Row> */}
            <Col>
              <Row
                className="justify-content-center pt-lg-5"
                style={{ minHeight: "50vh" }}
              >
                <Spinner
                  id="loading-spinner"
                  variant="primary"
                  animation="border"
                >
                  <span className="sr-only"></span>
                </Spinner>

                {reviewsTotal.map((review) => (
                  <Col md={6} lg={4} xl={3}>
                    <ReviewCard review={review} />
                  </Col>
                ))}
              </Row>
            </Col>
          </Container>
        )}
    </Layout>
  );
};

export default Reviews;
