import * as React from "react";

const SVGCircle = (props) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={110}
		height={110}
		viewBox="0 0 110 110"
		{...props}
	>
		<circle
			id="Ellipse_4"
			data-name="Ellipse 4"
			cx={55}
			cy={55}
			r={55}
			fill={props.fill || "#e0f1f9"}
		/>
	</svg>
);

export default SVGCircle;
