import React, { useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import SVGGoogle from "./SVG/google-svg";
import SVGCircle from "./SVG/circle";
import Link from "./link";
import moment from "moment";

import { StaticImage } from "gatsby-plugin-image";

const ReviewCard = ({ review }) => {
	var trimmedString = review.reviewBody.substr(0, 180);
	trimmedString =
		trimmedString.substr(
			0,
			Math.min(trimmedString.length, trimmedString.lastIndexOf(" "))
		) + "...";

	const [reviewBody, setReviewBody] = useState(review.reviewBody);
	const [linkText, setlinkText] = useState("Read More");
	const [expanded, setExpanded] = useState(false);
	const handleClick = () => {
		if (!expanded) {
			setReviewBody(review.reviewBody);
			setlinkText("Read Less");
			setExpanded(true);
		} else {
			setReviewBody(trimmedString);
			setlinkText("Read More");
			setExpanded(false);
		}
	};
	if (!expanded) setReviewBody(trimmedString);
	return (
		<Card className="border-0 review-card  my-4 mx-0">
			<Card.Header className="bg-white border-0">
				<Row className="align-items-center">
					<Col className="text-start" xs={9}>
						<Card.Title className=" jost-bold text-primary mb-0 mb-md-1 mb-lg-0">
							{review.author}
						</Card.Title>
						<p
							className=" text-start text-primary jost-regular pb-0 mb-0"
							style={{ fontSize: "1.2rem" }}
						>
							{moment(review.datePublished).format("YYYY-MM-DD")}
						</p>
					</Col>
					<Col xs={3} className="pt-2">
						{review.source === "google" && (
							<SVGGoogle style={{ maxWidth: "60px" }} />
						)}
						{review.source !== "google" && (
							<StaticImage
								src="../images/icon.png"
								className="review-image"
								alt="5 star review of local locksmith"
								placeholder="tracedSVG"
								quality="100"
								width={60}
								formats={["auto", "webp"]}
							/>
						)}
					</Col>
					<Row>
						<Col style={{ maxWidth: "90px" }} xs={4} lg={3}>
							{" "}
							<Row className="ps-2 pt-0  mt-0 ">
								<Col xs={2} className="px-1 mx-0 text-center">
									{" "}
									<StaticImage
										quality="100"
										src="../images/icons/star.svg"
										alt="star"
										placeholder="blurred"
										style={{ width: "10px" }}
										formats={["auto", "webp"]}
									/>
								</Col>
								<Col xs={2} className="px-1 mx-0 text-center">
									{" "}
									<StaticImage
										quality="100"
										src="../images/icons/star.svg"
										alt="star"
										placeholder="blurred"
										style={{ width: "10px" }}
										formats={["auto", "webp"]}
									/>
								</Col>
								<Col xs={2} className="px-1 mx-0 text-center">
									{" "}
									<StaticImage
										quality="100"
										src="../images/icons/star.svg"
										alt="star"
										placeholder="blurred"
										style={{ width: "10px" }}
										formats={["auto", "webp"]}
									/>
								</Col>
								<Col xs={2} className="px-1 mx-0 text-center">
									{" "}
									<StaticImage
										quality="100"
										src="../images/icons/star.svg"
										alt="star"
										placeholder="blurred"
										style={{ width: "10px" }}
										formats={["auto", "webp"]}
									/>
								</Col>
								<Col xs={2} className="px-1 mx-0 text-center">
									{" "}
									<StaticImage
										quality="100"
										src="../images/icons/star.svg"
										alt="star"
										placeholder="blurred"
										style={{ width: "10px" }}
										formats={["auto", "webp"]}
									/>
								</Col>
							</Row>
						</Col>
					</Row>
				</Row>
			</Card.Header>
			<Card.Body>
				<Row>
					<Col>
						<Card.Text className="text-start">
							<p>
								<span className="text-primary">"</span>
								{reviewBody}
								<span className="text-primary">"</span>
							</p>
						</Card.Text>
					</Col>
				</Row>
				<Row>
					<Col className="text-start">
						<Link className="big-link   fw-bold pointer" onClick={handleClick}>
							{linkText}
						</Link>
					</Col>
				</Row>
			</Card.Body>
		</Card>
	);
};

export default ReviewCard;
